import axios, { Axios } from "axios";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { ConfirmDialog } from "primereact/confirmdialog";
import { ContextMenu } from "primereact/contextmenu";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";


function ManageTags(props)
{

    const [tags,setTags] = useState([]);
    const [updateTags,setUpdateTags] = useState(0);
    const [selectedTag, setSelectedTag] = useState({value:"",count:""});
    const cm = useRef(null);
    const toast = useRef(null);
    const token = JSON.parse(localStorage.getItem('userToken'));
    const config = {  headers: {"Content-Type": "application/json",Authorization: `Bearer ${token}`} };
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const navigate = useNavigate();
    const [desktop, setDesktop] = useState(window.matchMedia("(min-width: 768px)").matches);
    
    const dateBodyTemplate = (rowData) => {
      return new Date(rowData.dateEdited).toLocaleDateString('fa-IR');
  }
  const secondryKeywordsBodyTemplate = (rowData) => {
    if(rowData.secondryKeywords)
      return  rowData.secondryKeywords.join("-");
    else
      return '';
}

    const metaBodyTemplate = (rowData) => {
      const maxLength = 100;
      const content = rowData.metaDescription || "";
    
      // Truncate content if it exceeds maxLength
      const truncatedContent = content.length > maxLength ? content.substring(0, maxLength) + '...' : content;
    
      return <div style={{width:'200px',wordWrap: "break-word"}}>{truncatedContent}</div>;
    };

    const showIconBodyTemplate = (rowData) =>
      {
        return <div style={{display:'flex' , flexDirection:'row', alignItems:'center'}}>
          <button  
            className="login-button"
            onClick={() => window.open("/tags/" + rowData.value, "_blank")}
        >
          نمایش</button>
          </div>
      }

      const rankBodyTemplate = (rowData) =>
        {
          
          if(rowData.rank!==undefined)
            return (
              <div style={{display:"flex", alignItems:"center"}}>
                  {( rowData.rank<10) && 
                  (<i className="pi pi-star-fill" 
                    style={{background: "green",borderRadius: "15px",width:"30px",height:"30px",padding: "10px",color: "#fff", fontSize:"11px",display:"flex",alignItems:"center"}}>
                  </i>)}
              <Button 
                tooltip="آپدیت رتبه گوگل" tooltipOptions={{position:"top"}}
                style={{color:rowData.rank >= 20 ? "red" : "green", fontSize:"20px",cursor:"pointer",background:'transparent',border:"none"}}
                onClick={() => calculateRank(rowData.seoTitle)}
                  >{rowData.rank}</Button>
                </div> );
          else
            return(<Button 
                    tooltip="محاسبه رتبه گوگل" tooltipOptions={{position:"top"}}
                    style={{color:"red", fontSize:"20px",cursor:"pointer",background:'transparent',border:"none"}}
                    onClick={() => calculateRank(rowData.seoTitle)}
                      >؟</Button> );
          
          
        };
    
        const calculateRank = async (seoTitle) =>
        {
          await axios.get(process.env.REACT_APP_BACK+"/posts/tags/calculate_rank/"+seoTitle, config)
          .then(response => 
            {
              if (response.status == 200)
              {
                
                if(response.data !=="error")
                  toast.current.show({severity: 'info', summary:"رتبه گوگل آپدیت شد" }); 
                else
                  toast.current.show({severity: 'error', summary:"مشکلی در آپدیت رتبه گوگل رخ داد" }); 
                setUpdateTags(updateTags+1);
                
              }
    
          })
          .catch(error => {
             console.log(error);
             if(error.response.data.statusCode===401)
                  navigate("/login")
          });
        }
    
    
    const menuModel = [
      {label: 'ویرایش', icon: 'pi pi-fw pi-pencil', command: () => navigate("/edit-tag/"+selectedTag.value)},
        {label: 'حذف', icon: 'pi pi-fw pi-trash', command: () => setShowConfirmDelete(true)}
    ];


    const deleteTagFromDB = async (value) => 
    {
      const encodedValue = encodeURIComponent(value);

      await axios.delete(process.env.REACT_APP_BACK+"/posts/delete-tag/" + encodedValue, config)
      .then(response => 
        {
          if (response.status == 200)
          {
            console.log(response);      
            toast.current.show({severity: 'info', summary: 'برچسب حذف شد'});
            setUpdateTags(updateTags+1);    
            
          }

      })
      .catch(error => {
         console.log(error);
         if(error.response.data.statusCode===401)
              navigate("/login")
      });
    };

   

    //get all tags from database:
    useEffect(() => {
    async function getAllTags() {

      
      const result = await axios.get(process.env.REACT_APP_BACK+"/posts/tagsForManager", config)
      .then((result) => {
        if (result.status == 200) {
          setTags(result.data);
          return result.data;
        }
      })
      .catch((error) => {
        console.log(error);
        if(error.response.data.statusCode===401)
              navigate("/login")
      });
      return result.data;

    }
    getAllTags();
  },[updateTags]);


  //update and correct tags in db:
  const updateAndCorrectTags = async () =>
  {
    const result = await axios.get(process.env.REACT_APP_BACK+"/posts/correct_all-tags/", config)
    .then((result) => {
      if (result.status == 200) {
      
        toast.current.show({severity: 'info', summary: 'همه تگ ها اصلاح شدن'}); 
        setUpdateTags(updateTags+1);    
        return result.data;
      }
    })
    .catch((error) => {
      console.log(error);
      if(error.response.data.statusCode===401)
            navigate("/login")
    });

  }

  const generateArticles= async () =>
    {
      await axios.get(process.env.REACT_APP_BACK+"/sitemap/create_tag_articles", config)
      .then(response => 
        {

          
          if (response.status == 200)
          {
            if(response.data!=="empty")
            {
              toast.current.show({severity: 'info', summary: 'مقاله های امروز تولید شد'}); 
              setUpdateTags(updateTags+1);
            }
            else
            toast.current.show({severity: 'error', summary: 'فایل متن ورودی خالی است'}); 

            
          }

      })
      .catch(error => {
         console.log(error);
         if(error.response.data.statusCode===401)
              navigate("/login")
      });
    }


  


    

    return(

        <div id="manage-pages">

            <h2>
            مدیریت برچسب ها
            </h2>

            <span style={{display:'flex' , paddingRight: desktop ? '50px' : '15px', paddingBottom:'50px'}}>


                <button 
            style={{marginTop:"50px",marginRight:'10px',background:"#af734f", color:"#ffffff", border: "#af734f 1px solid", borderRadius:'30px', padding:'10px 30px' , cursor:'pointer'}} 
            onClick={updateAndCorrectTags}
            >
               آپدیت و اصلاح همه تگ ها</button>

               <button 
            style={{marginTop:"50px",marginRight:'10px',background:"transparent", color:"#af734f", border: "#af734f 1px solid", borderRadius:'30px', padding:'10px 30px' , cursor:'pointer'}} 
            onClick={generateArticles}
            >
               تولید محتوا برای تگ ها</button>
            </span>

            <Toast ref={toast}></Toast>

            <ContextMenu model={menuModel} ref={cm} />

            <DataTable size="normal" value={tags} paginator rows={20} editMode="row"
            className="manager-table"
            contextMenuSelection={selectedTag}
            onContextMenuSelectionChange={e => setSelectedTag(e.value)}
            onContextMenu={e => cm.current.show(e.originalEvent)}>
            
            
            <Column alignHeader="center" columnKey="value" align="center" field="value" header="نام برچسب" filter   ></Column>
            <Column alignHeader="center" columnKey="seoTitle" align="center" field="seoTitle" header="کلمه کلیدی" filter   ></Column>
            <Column alignHeader="center" columnKey="secondryKeywords" align="center" field="secondryKeywords" header="کلمات کلیدی فرعی"  body={secondryKeywordsBodyTemplate}  ></Column>
            <Column alignHeader="center" columnKey="count" align="center" field="count" header="تعداد استفاده" sortable ></Column>
            <Column alignHeader="center" columnKey="metaDescription" align="center" field="metaDescription" header="توضیحات متا" body={metaBodyTemplate}  ></Column>
            <Column alignHeader="center" columnKey="visits" align="center" field="visits" header="تعداد بازدیدها" sortable ></Column>
            <Column alignHeader="center" columnKey="rank" align="center" field="rank" header="رتبه گوگل" sortable  body={rankBodyTemplate}></Column>
            <Column alignHeader="center" field="dateEdited" align="center" header="تاریخ ویرایش" body={dateBodyTemplate} sortable></Column>
            <Column alignHeader="center" columnKey="show" align="center" field="show" header="" body={showIconBodyTemplate} ></Column>
            
    
            
            
            </DataTable>


            <ConfirmDialog 
            className={props.theme}
            visible={showConfirmDelete} 
            onHide={() => setShowConfirmDelete(false)} 
            message="آیا از حذف این برچسب مطمئنید؟"
            header="تایید حذف"
            icon="pi pi-info-circle"
            acceptLabel="بله"
            rejectLabel="نه"
            acceptClassName="accept"
            rejectClassName="reject"
            accept={() => deleteTagFromDB(selectedTag.value)} 
            reject={() => setShowConfirmDelete(false)} />

           

        </div>
    );
}
  
export default ManageTags;